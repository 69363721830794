import React from "react";
import AboutImg from "../assets/images/about-img2.png"

const HomeAbout = () => {
    return (
        <>
            <section className="about-section position-relative">
                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="about-img">
                                {/* <div className="vector-one"></div> */}
                                <div className="vector-two"></div>
                                <div className="vector-three"></div>
                                <img src={AboutImg} alt="about-img" className="move-animation-morphing " />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="about-content">

                                <div className="site-title">
                                    <p>Why Choose us</p>
                                    <h3>What you will get if you start your business with us</h3>
                                </div>
                                <p>
                                    VIVID web solution is a global IT Solution company based in Surat, India. Providing IT
                                    Solution like Website Development, Website Design, Mobile app Development, Custom Software Development and More.
                                </p>
                                <p>
                                    It is a customer focused and technology driven company providing product engineering and
                                    enterprise software/ application for their IT efforts. Highly qualitative, timely
                                    delivered and cost effective client solutions are our main focus. Over the years Key
                                    Concepts has earned ineffaceable reputation by engineering.
                                </p>
                                {/* <p>
                                    Business centric solution so we have now spread our roots worldwide.
                                </p> */}
                                {/* <a href="#" className="vs-btn about-btn">See More</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="project-counter">
                     
                           
                                <div className="counter-box position-relative">
                                    <h3>12+</h3>
                                    <div className="counter-inner">
                                        <h4>Experience Year</h4>
                                    </div>
                                </div>
                           
                                <div className="counter-box position-relative">
                                    <h3>700+</h3>
                                    <div className="counter-inner">
                                        <h4>Successful Project</h4>
                                    </div>
                                </div>
                           
                                <div className="counter-box position-relative">
                                    <h3>190+</h3>
                                    <div className="counter-inner">
                                        <h4>Happy Customer</h4>
                                    </div>
                                </div>
                          
                                <div className="counter-box position-relative">
                                    <h3>16+</h3>
                                    <div className="counter-inner">
                                        <h4>Our Experts</h4>
                                    </div>
                                </div>
                       
                    </div>
                </div>
            </section>
          
        </>
    )
}

export default HomeAbout;