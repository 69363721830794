import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLocation, useNavigate} from "react-router-dom";
import Logo from "../assets/images/logo-invert 2.svg";
import LogoW from "../assets/images/whitelogo.svg";


const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Services = [
    { id: "php_section", label: "PHP/MYSQL Development" },
    { id: "uiux_section", label: "UI/UX Design" },
    { id: "wordpress_section", label: "WordPress Development" },
    { id: "ecomm_section", label: "E-Commerce Development" },
    { id: "cms_section", label: "CMS & MVC Development" },
    { id: "api_section", label: "API Development" },
    { id: "mainsup_section", label: "Maintenance and Support" },
  ];

  const serviceNav = (id) => {
    navigate("/service", { state: Services[id] });
  };

  const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 50);
   });
 }, []);

//  const cursorDotOutline = React.useRef();
//  const cursorDot = React.useRef();
//  const requestRef = React.useRef();
//  const previousTimeRef = React.useRef();
//  let [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });
//  const [width, setWidth] = React.useState(window.innerWidth);
//  const [height, setHeight] = React.useState(window.innerHeight);
//  let cursorVisible = React.useState(false);
//  let cursorEnlarged = React.useState(false);

//  /**
//   * Mouse Moves
//   */
//  const onMouseMove = event => {
//    const { pageX: x, pageY: y } = event;
//    setMousePosition({ x, y });
//    positionDot(event);
//  };
//  const onMouseEnter = () => {
//    cursorVisible.current = true;
//    toggleCursorVisibility();
//  };
//  const onMouseLeave = () => {
//    cursorVisible.current = false;
//    toggleCursorVisibility();
//  };
//  const onMouseDown = () => {
//    cursorEnlarged.current = true;
//    toggleCursorSize();
//  };
//  const onMouseUp = () => {
//    cursorEnlarged.current = false;
//    toggleCursorSize();
//  };
//  const onResize = event => {
//    setWidth(window.innerWidth);
//    setHeight(window.innerHeight);
//  };

//  /**
//   * Hooks
//   */
//  React.useEffect(() => {
//    document.addEventListener("mousemove", onMouseMove);
//    document.addEventListener("mouseenter", onMouseEnter);
//    document.addEventListener("mouseleave", onMouseLeave);
//    document.addEventListener("mousedown", onMouseDown);
//    document.addEventListener("mouseup", onMouseUp);
//    window.addEventListener("resize", onResize);
//    requestRef.current = requestAnimationFrame(animateDotOutline);
   
//    // Handle Link Hovers
//    handleLinkHovers();

//    return () => {
//      document.removeEventListener("mousemove", onMouseMove);
//      document.removeEventListener("mouseenter", onMouseEnter);
//      document.removeEventListener("mouseleave", onMouseLeave);
//      document.removeEventListener("mousedown", onMouseDown);
//      document.removeEventListener("mouseup", onMouseUp);
//      window.removeEventListener("resize", onResize);
//      cancelAnimationFrame(requestRef.current);
//    };
//  }, []);

//  let { x, y } = mousePosition;
//  const winDimensions = { width, height };
//  let endX = winDimensions.width / 2;
//  let endY = winDimensions.height / 2;

//  /**
//   * Position Dot (cursor)
//   * @param {event}
//   */
//  function positionDot(e) {
//    cursorVisible.current = true;
//    toggleCursorVisibility();
//    // Position the dot
//    endX = e.pageX;
//    endY = e.pageY;
//    cursorDot.current.style.top = endY + "px";
//    cursorDot.current.style.left = endX + "px";
//  }

//  /**
//   * Toggle Cursor Visiblity
//   */
//  function toggleCursorVisibility() {
//    if (cursorVisible.current) {
//      cursorDot.current.style.opacity = 1;
//      cursorDotOutline.current.style.opacity = 1;
//    } else {
//      cursorDot.current.style.opacity = 0;
//      cursorDotOutline.current.style.opacity = 0;
//    }
//  }

//  /**
//   * Toggle Cursor Size
//   */
//  function toggleCursorSize() {
//    if (cursorEnlarged.current) {
//      cursorDot.current.style.transform = "translate(-50%, -50%) scale(0.7)";
//      cursorDotOutline.current.style.transform =
//        "translate(-50%, -50%) scale(5)";
//    } else {
//      cursorDot.current.style.transform = "translate(-50%, -50%) scale(1)";
//      cursorDotOutline.current.style.transform =
//        "translate(-50%, -50%) scale(1)";
//    }
//  }

//  /**
//   * Handle LInks
//   * Applies mouseover/out hooks on all links
//   * to trigger cursor animation
//   */
//  function handleLinkHovers() {
//    document.querySelectorAll("a").forEach(el => {
//      el.addEventListener("mouseover", () => {
//        cursorEnlarged.current = true;
//        toggleCursorSize();
//      });
//      el.addEventListener("mouseout", () => {
//        cursorEnlarged.current = false;
//        toggleCursorSize();
//      });
//    });
//  }

//  /**
//   * Animate Dot Outline
//   * Aniamtes cursor outline with trailing effect.
//   * @param {number} time
//   */
//  const animateDotOutline = time => {
//    if (previousTimeRef.current !== undefined) {
//      x += (endX - x) / 8;
//      y += (endY - y) / 8;
//      cursorDotOutline.current.style.top = y + "px";
//      cursorDotOutline.current.style.left = x + "px";
//    }
//    previousTimeRef.current = time;
//    requestRef.current = requestAnimationFrame(animateDotOutline);
//  };

  return (
    <>
      {/* <div className="cursor"></div> */}
      {/* <div ref={cursorDotOutline} id="cursor-dot-outline" />
      <div ref={cursorDot} id="cursor-dot" /> */}
      <Navbar expand="lg" id="siteheaders" collapseOnSelect className={scroll ? "sticky" : "one"}>
        <Container>
          <Navbar.Brand
          
            onClick={() => navigate("/")}
            className="innerpage-logo"
          >

            <img
              src={Logo}
              alt="VIVID WEB SOLUTIONS"
              className=" innerpage-stickylogo"
            />
            <img
              src={LogoW}
              alt="VIVID WEB SOLUTIONS"
              className="innerpage-logo"
            />

          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            id="basic-navbar-nav"
            className={location.pathname != "/" ? " navigation_inner" : ""}
          >
            <Nav>
              <Nav.Link
                href="#"
                
                onClick={() => navigate("/about")}
                active={location.pathname === "/about" ? true : false}
              >
                About Us
              </Nav.Link>

              <NavDropdown
                title="Service"
                id="basic-nav-dropdown"
                active={location.pathname === "/service" ? true : false}
              >
                {Services.map((service, idx) => (
                  <NavDropdown.Item onClick={() => serviceNav(idx)} key={idx}>
                    {service.label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              {/* <Nav.Link
                href="/portfolio"
              
                active={location.pathname === "/portfolio" ? true : false}
              >
                Portfolio
              </Nav.Link> */}
              <Nav.Link
                href="#"
                onClick={() => navigate("/career")}
                active={location.pathname === "/career" ? true : false}
              >
                Career
              </Nav.Link>
              <Nav.Link
                href="#"
                onClick={() => navigate("/contact")}
                active={location.pathname === "/contact" ? true : false}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
