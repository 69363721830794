import React from "react";
import wordpress from "../assets/images/wordpress.svg"
import php from "../assets/images/php.svg"
import laravel from "../assets/images/laravel.svg"
import woocommerce from "../assets/images/woocommerce.svg"
import nodejs from "../assets/images/nodejs.svg"
import react from "../assets/images/react.svg"
import nextjs from "../assets/images/next-js.svg"
import angular from "../assets/images/angular.svg"
import vue from "../assets/images/vue.svg"
import html from "../assets/images/html.svg"
import css from "../assets/images/css.svg"
import emotion from "../assets/images/emotion.svg"
import javascript from "../assets/images/javascript.svg"
import jquery from "../assets/images/jquery.svg"
import bootstrap5 from "../assets/images/bootstrap-5.svg"
import sass from "../assets/images/sass.svg"
import tailwind from "../assets/images/tailwind.svg"
import elementor from "../assets/images/elementor.svg"
import figma from "../assets/images/figma.svg"
import adobephotoshop from "../assets/images/adobe-photoshop.svg"
import adobeXd from "../assets/images/adobe-xd.svg"
import codeigniter from "../assets/images/codeigniter.svg"
import wix from "../assets/images/wix.svg"
import shopify from "../assets/images/shopify.svg"
import visualcomposer from "../assets/images/visual-composer.svg"
import prestashop from "../assets/images/prestashop 1.svg"
import squarespace from "../assets/images/squarespace.svg"
import yii from "../assets/images/yii.svg"
import buddypress from "../assets/images/buddypress.svg"
import sketch from "../assets/images/sketch.svg"
import zeplin from "../assets/images/zeplin.svg"
import opencart from "../assets/images/opencart.png"
import bigcommerce from "../assets/images/bigcommerce.png"

const Technology = () => {
    return (
        <>
            <section className="services-sec">
                <div className="marquee">
                    <div className="marquee__content">
                        <ul className="services-mr-tag">
                            <li><img src={react} alt="react.js" />React.js</li>
                            <li><img src={nextjs} alt="next.js" />Next.js</li>
                            <li><img src={angular} alt="angular" />Angular</li>
                            <li><img src={wordpress} alt="wordpress" />WordPress</li>
                            <li><img src={elementor} alt="elementor" />Elementor</li>
                            <li><img src={html} alt="html" />HTML</li>
                            <li><img src={css} alt="css" />CSS</li>
                            <li><img src={sass} alt="sass" />Sass</li>
                            <li><img src={tailwind} alt="tailwind" />tailwind</li>
                            <li><img src={emotion} alt="emotion css" />Emotion css</li>
                            <li><img src={javascript} alt="javascript" />javascript</li>
                            <li><img src={jquery} alt="jquery" />jQuery</li>
                            <li><img src={bootstrap5} alt="bootstrap" />Bootstrap</li>
                            <li><img src={figma} alt="figma" />Figma</li>
                            <li><img src={adobephotoshop} alt="figma" />Adobe Photoshop</li>
                            <li><img src={adobeXd} alt="adobeXd" />Adobe Xd</li>
                        </ul>
                        <ul className="services-mr-tag">
                            <li><img src={react} alt="react.js" />React.js</li>
                            <li><img src={nextjs} alt="next.js" />Next.js</li>
                            <li><img src={angular} alt="angular" />Angular</li>
                            <li><img src={wordpress} alt="wordpress" />WordPress</li>
                            <li><img src={elementor} alt="elementor" />WordPress</li>
                            <li><img src={html} alt="html" />HTML</li>
                            <li><img src={css} alt="css" />CSS</li>
                            <li><img src={sass} alt="sass" />Sass</li>
                            <li><img src={tailwind} alt="tailwind" />tailwind</li>
                            <li><img src={emotion} alt="emotion css" />Emotion css</li>
                            <li><img src={javascript} alt="javascript" />javascript</li>
                            <li><img src={jquery} alt="jquery" />jQuery</li>
                            <li><img src={bootstrap5} alt="bootstrap" />Bootstrap</li>
                            <li><img src={figma} alt="figma" />Figma</li>
                            <li><img src={adobephotoshop} alt="figma" />Adobe Photoshop</li>
                            <li><img src={adobeXd} alt="adobeXd" />Adobe Xd</li>
                        </ul>
                        <ul className="services-mr-tag">
                            <li><img src={react} alt="react.js" />React.js</li>
                            <li><img src={nextjs} alt="next.js" />Next.js</li>
                            <li><img src={angular} alt="angular" />Angular</li>
                            <li><img src={wordpress} alt="wordpress" />WordPress</li>
                            <li><img src={elementor} alt="elementor" />WordPress</li>
                            <li><img src={html} alt="html" />HTML</li>
                            <li><img src={css} alt="css" />CSS</li>
                            <li><img src={sass} alt="sass" />Sass</li>
                            <li><img src={tailwind} alt="tailwind" />tailwind</li>
                            <li><img src={emotion} alt="emotion css" />Emotion css</li>
                            <li><img src={javascript} alt="javascript" />javascript</li>
                            <li><img src={jquery} alt="jquery" />jQuery</li>
                            <li><img src={bootstrap5} alt="bootstrap" />Bootstrap</li>
                            <li><img src={figma} alt="figma" />Figma</li>
                            <li><img src={adobephotoshop} alt="figma" />Adobe Photoshop</li>
                            <li><img src={adobeXd} alt="adobeXd" />Adobe Xd</li>
                        </ul>
                    </div>
                </div>
                <div className="marquee">
                    <div className="marquee__content">
                        <ul className="services-mr-tag">
                            <li><img src={php} alt="php" />PHP</li>
                            <li><img src={laravel} alt="laravel" />Laravel</li>
                            <li><img src={codeigniter} alt="codeigniter" />Codeigniter</li>
                            <li><img src={woocommerce} alt="woocommerce" />Woocommerce</li>
                            <li><img src={wix} alt="wix" />Wix</li>
                            <li><img src={shopify} alt="shopify" />Shopify</li>
                            <li><img src={nodejs} alt="node.js" />Node.js</li>
                            <li><img src={vue} alt="vue" />Vue.js</li>
                            <li><img src={visualcomposer} alt="visualcomposer" />Visual Composer</li>
                            <li><img src={prestashop} alt="prestashop" />Prestashop</li>
                            <li><img src={squarespace} alt="squarespace" />Square Space</li>
                            <li><img src={yii} alt="yii" />Yii</li>
                            <li><img src={buddypress} alt="buddypress" />BuddyPress</li>
                            <li><img src={sketch} alt="sketch" />Sketch</li>
                            <li><img src={zeplin} alt="zeplin" />Zeplin</li>
                            <li><img src={opencart} alt="opencart" />OpenCart</li>
                            <li><img src={bigcommerce} alt="bigcommerce" />BigCommerce</li>
                        </ul>
                        <ul className="services-mr-tag">
                            <li><img src={php} alt="php" />PHP</li>
                            <li><img src={laravel} alt="laravel" />Laravel</li>
                            <li><img src={codeigniter} alt="codeigniter" />Codeigniter</li>
                            <li><img src={woocommerce} alt="woocommerce" />Woocommerce</li>
                            <li><img src={wix} alt="wix" />Wix</li>
                            <li><img src={shopify} alt="shopify" />Shopify</li>
                            <li><img src={nodejs} alt="node.js" />Node.js</li>
                            <li><img src={vue} alt="vue" />Vue.js</li>
                            <li><img src={visualcomposer} alt="visualcomposer" />Visual Composer</li>
                            <li><img src={prestashop} alt="prestashop" />Prestashop</li>
                            <li><img src={squarespace} alt="squarespace" />Square Space</li>
                            <li><img src={yii} alt="yii" />Yii</li>
                            <li><img src={buddypress} alt="buddypress" />BuddyPress</li>
                            <li><img src={sketch} alt="sketch" />Sketch</li>
                            <li><img src={zeplin} alt="zeplin" />Zeplin</li>
                            <li><img src={opencart} alt="opencart" />OpenCart</li>
                            <li><img src={bigcommerce} alt="bigcommerce" />BigCommerce</li>
                        </ul>
                        <ul className="services-mr-tag">
                            <li><img src={php} alt="php" />PHP</li>
                            <li><img src={laravel} alt="laravel" />Laravel</li>
                            <li><img src={codeigniter} alt="codeigniter" />Codeigniter</li>
                            <li><img src={woocommerce} alt="woocommerce" />Woocommerce</li>
                            <li><img src={wix} alt="wix" />Wix</li>
                            <li><img src={shopify} alt="shopify" />Shopify</li>
                            <li><img src={nodejs} alt="node.js" />Node.js</li>
                            <li><img src={vue} alt="vue" />Vue.js</li>
                            <li><img src={visualcomposer} alt="visualcomposer" />Visual Composer</li>
                            <li><img src={prestashop} alt="prestashop" />Prestashop</li>
                            <li><img src={squarespace} alt="squarespace" />Square Space</li>
                            <li><img src={yii} alt="yii" />Yii</li>
                            <li><img src={buddypress} alt="buddypress" />BuddyPress</li>
                            <li><img src={sketch} alt="sketch" />Sketch</li>
                            <li><img src={zeplin} alt="zeplin" />Zeplin</li>
                            <li><img src={opencart} alt="opencart" />OpenCart</li>
                            <li><img src={bigcommerce} alt="bigcommerce" />BigCommerce</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Technology;