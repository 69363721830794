import React from "react";

const HomeWhyChoose = () => {
    return (
        <>
            <section className="whychoose-us-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="whychoose-content">
                                <div className="site-title">
                                    <p>Why Choose Us</p>
                                    <h3>What you will get if you start your business with us</h3>
                                </div>
                                <p>
                                    VIVID web solution is a global IT Solution company based in Surat, India. Providing IT
                                    Solution like Website Development,
                                </p>
                                <p>
                                    Business centric solution so we have now spread our roots worldwide.
                                </p>
                                <a href="/service" className="vs-btn about-btn">See More</a>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="services-list">
                                <div className="services-list-box php-mysql">
                                    {/* <span className="services-icon-add"></span> */}
                                    <span className="icon-animation"></span>
                                    <h3>PHP/MYSQL Development</h3>
                                    <p>
                                        Website Development has always remained a real time challenge in the world of internet. But Vivid Web Solutions team , faces this challenge with ultimate prudency and provides the most effective solutions to clients. Since, we are a trusted Website Development company in Surat, we have been frequently sought for Website Designing & Developing services.
                                    </p>
                                </div>
                                <div className="services-list-box ui-ux">
                                    <span className="icon-animation"></span>
                                    <h3>UI/UX Design</h3>
                                    <p>
                                        Our designing team is working really hard to give most creative web design concepts to the clients and fulfil their expectations.
                                    </p>
                                </div>
                                <div className="services-list-box wordpress">
                                    <span className="icon-animation"></span>
                                    <h3>WordPress Development</h3>
                                    <p>
                                        We have dedicated team which has expertise in WORDPRESS. We Prove our strength by deliver big range on custom plugin and theme.
                                    </p>
                                </div>
                                <div className="services-list-box e-commerce">
                                    <span className="icon-animation"></span>
                                    <h3>E-Commerce Development</h3>
                                    <p>
                                        We provide E-Commerce solution that helps a lot to increase business worldwide. No one can stop us from growing in business if we have proper, well developed website. 
                                    </p>
                                </div>
                                <div className="services-list-box cms-mvc">
                                    <span className="icon-animation"></span>
                                    <h3>CMS & MVC Development</h3>
                                    <p>
                                        CMS Content management system is a computer program that allows publishing, editing and modifying content as well as maintenance from a central interface.
                                    </p>
                                </div>
                                <div className="services-list-box api">
                                    <span className="icon-animation"></span>
                                    <h3>API Development</h3>
                                    <p>
                                        We have dedicated team which has expertise in web API Soap and rest. For better understanding we prepare API user guide, train our clients for all scenario. Complete API development and full support is our strength.
                                    </p>
                                </div>
                                <div className="services-list-box maintenance">
                                    <span className="icon-animation"></span>
                                    <h3>Maintenance and Support</h3>
                                    <p>
                                        VIVID web solutions provide elegant and powerful web solution with excellent Quality. We also offering maintenance support to maintain the quality. Within or after the development and delivery, Complete support is our guarantee. All clients are given equal importance and full follow up.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeWhyChoose;