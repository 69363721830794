import { useEffect } from "react";
import HomeBanner from "../section/HomeBanner";
import Technology from "../section/Technology";
import HomeAbout from "../section/HomeAbout";
import HomeWhyChoose from "../section/HomeWhyChoose";
import HomeTestimonial from "../section/HomeTestimonial";
import Header from "../section/Header";
import Footer from "../section/Footer";
import HomePortfolio from "../section/HomePortfolio";
import { useLocation } from 'react-router-dom';

function Home() {

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <>
    <Header/>
    <HomeBanner/>
    <Technology/>
    <HomeAbout/>
    <HomeWhyChoose/>
    {/* <HomePortfolio/> */}
    <HomeTestimonial/>
    <Footer/>
    </>
  )
}

export default Home;
