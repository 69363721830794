import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import HeroBanner from "../assets/images/hero-banner.jpg";
import FphoneIcon from "../assets/images/f-phone.png";
import FpinIcon from "../assets/images/f-pin.png";
import FenvelopIcon from "../assets/images/f-envelope.png";
import ListGroup from "react-bootstrap/ListGroup";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import Header from "../section/Header";
import Footer from "../section/Footer";

function Career() {

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [validation, setValidation] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const [isSubmited, setIsSubmited] = useState(false);

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name == "mobile") {
      const PhoneReg = /^[\d+]+$/;
      if (PhoneReg.test(value) || value == "") {
        setData({
          ...data,
          [name]: value,
        });
      }
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }

    if (isSubmited) {
      validate(name, value);
    }
  };

  const validate = (name, value) => {
    if (name == "email") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value == "" || value == null) {
        setValidation({
          ...validation,
          email: (
            <small className="text-danger">
              Please enter your email address
            </small>
          ),
        });
      } else if (re.test(value) == false) {
        setValidation({
          ...validation,
          email: (
            <small className="text-danger">
              Please enter a valid email address
            </small>
          ),
        });
      } else {
        setValidation({
          ...validation,
          email: "",
        });
      }
    }

    if (name == "name") {
      if (value == "" || value == null) {
        setValidation({
          ...validation,
          name: <small className="text-danger">Please enter your name</small>,
        });
      } else {
        setValidation({
          ...validation,
          name: "",
        });
      }
    }

    if (name == "mobile") {
      const phoneReg =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      if (value == "" || value == null) {
        setValidation({
          ...validation,
          mobile: (
            <small className="text-danger">
              Please enter your phone number
            </small>
          ),
        });
      } else if (phoneReg.test(value) == false) {
        setValidation({
          ...validation,
          mobile: (
            <small className="text-danger">
              Please enter a valid phone number
            </small>
          ),
        });
      } else {
        setValidation({
          ...validation,
          mobile: "",
        });
      }
    }
  };

  const submit = async () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneReg =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    var submit_error = {
      name: "",
      email: "",
      mobile: "",
    };

    var error = false;

    if (data.email == "" || data.email == null) {
      error = true;
      submit_error.email = (
        <small className="text-danger">Please enter your email address</small>
      );
    } else if (re.test(data.email) == false) {
      error = true;
      submit_error.email = (
        <small className="text-danger">
          Please enter a valid email address
        </small>
      );
    }

    if (data.name == "" || data.name == null) {
      error = true;
      submit_error.name = (
        <small className="text-danger">Please enter your name</small>
      );
    }

    if (data.mobile == "" || data.mobile == null) {
      error = true;
      submit_error.mobile = (
        <small className="text-danger">Please enter your phone number</small>
      );
    } else if (phoneReg.test(data.mobile) == false) {
      error = true;
      submit_error.mobile = (
        <small className="text-danger">Please enter a valid phone number</small>
      );
    }

    setValidation(submit_error);
    setIsSubmited(true);

    // if (!error) {
    //   let fdata = new FormData();
    //   fdata.append("name", data.name);
    //   fdata.append("email", data.email);
    //   fdata.append("mobile", data.mobile);
    //   fdata.append("message", data.message);

    //   setIsLoading(true);

    //   await createContact(fdata)
    //     .then((res) => {
    //       setIsLoading(false);
    //       toast.success(
    //         "Thank you for submit your form. Our Team will contact you soon.",
    //         {
    //           position: "top-right",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         }
    //       );
    //       setData({
    //         name: "",
    //         email: "",
    //         mobile: "",
    //         message: "",
    //       });
    //     })
    //     .catch((err) => {
    //       setIsLoading(false);
    //       console.log(err);
    //     });
    // }

    
  };

  return (
    <>
    <Header/>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="innerhero-banner">
        <div className="Hero-Banner-slider">
          <div className="banner-slide">
            <img src={HeroBanner} alt="vivid web solution" />
            <div className="banner-content">
              <Container>
                <h1 className="slider-title">Contact</h1>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <div className="p-70 contact-info-sec contactus_container">
        <Container>
          <ListGroup as="ul" className="comp-info">
            <ListGroup.Item as="li" className="comp-info-li">
              <div className="comp-info-box">
                <span>
                  <img src={FphoneIcon} alt="VIVID WEB SOLUTIONS" />
                </span>
                <div className="comp-info-text">
                  <strong>Call us</strong>
                  <Link
                    to="#"
                    onClick={() => (window.location = "tel:+918469715630")}
                  >
                    +91 8469715630
                  </Link>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="comp-info-li">
              <div className="comp-info-box">
                <span>
                  <img src={FpinIcon} alt="VIVID WEB SOLUTIONS" />
                </span>
                <div className="comp-info-text">
                  <strong>Office location</strong>
                  <Link
                    to="#"
                    onClick={() =>
                      (window.location = "https://maps.google.com/?q=vivid")
                    }
                  >
                    359, Shiven square business hub, pal, Surat
                  </Link>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="comp-info-li">
              <div className="comp-info-box">
                <span>
                  <img src={FenvelopIcon} alt="VIVID WEB SOLUTIONS" />
                </span>
                <div className="comp-info-text">
                  <strong>Write to us</strong>
                  <Link
                    to="#"
                    onClick={() =>
                      (window.location = "mailto:yourmail@domain.com")
                    }
                  >
                    info@vividwebsolutions.in
                  </Link>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>

          {/* <div className="contact-form">
            <h2>
              <small>LET'S TALK</small>Quick Contact
            </h2>
            <Form>
              <Row>
                <Col md={12}>
                  <FloatingLabel
                    controlId="floatingName"
                    label="Your Name"
                    className="mb-4"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      value={data.name}
                      onChange={(e) => onChange(e)}
                    />
                    {validation.name}
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    controlId="floatingPhone"
                    label="Phone"
                    className="mb-4"
                  >
                    <Form.Control
                      type="mobile"
                      value={data.mobile}
                      placeholder="Enter Mobile"
                      name="mobile"
                      onChange={(e) => onChange(e)}
                    />
                    {validation.mobile}
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    controlId="floatingEmail"
                    label="Email"
                    className="mb-4"
                  >
                    <Form.Control
                      type="text"
                      value={data.email}
                      placeholder="Enter Email"
                      name="email"
                      onChange={(e) => onChange(e)}
                    />
                    {validation.email}
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel controlId="floatingMassage" label="Message">
                    <Form.Control
                      as="textarea"
                      type="text"
                      rows={5}
                      style={{ resize: "none" }}
                      value={data.message}
                      placeholder="Enter Message"
                      name="message"
                      onChange={(e) => onChange(e)}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <br />
                  <Button className="btn-defult vs-btn" onClick={submit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div> */}
        </Container>
      </div>
      <div className="contactmap-sec">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1860.0630117668536!2d72.78206385872193!3d21.187152100000013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8284e24ee287274!2sVivid%20Web%20Solutions!5e0!3m2!1sen!2sin!4v1661153620582!5m2!1sen!2sin"
          width="600"
          height="450"
        ></iframe>
      </div>
      {/* {isLoading && <CustomLoader />} */}
      <Footer/>
    </>
  );
}

export default Career;
