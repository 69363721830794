import React, { useEffect, useRef } from "react";


const HomeBanner = () => {
    const imageRef = useRef(null);

    useEffect(() => {
        const loadScripts = () => {
          const script1 = document.createElement("script");
          script1.src = "https://code.jquery.com/jquery-3.4.1.min.js";
          script1.async = true;
    
          const script2 = document.createElement("script");
          script2.src =
            "https://cdnjs.cloudflare.com/ajax/libs/jquery.ripples/0.5.3/jquery.ripples.min.js";
          script2.async = true;
    
          script1.onload = () => {
            document.body.appendChild(script2);
          };
    
          script2.onload = () => {
            if (imageRef.current) {
              window.$(imageRef.current).ripples({
                resolution: 200,
                perturbance: 0.004,
              });
            }
          };
    
          document.body.appendChild(script1);
        };
    
        loadScripts();
      }, []);
    return(
        <>
            <section className="hero-banner" ref={imageRef}>
                <div className="container">
                    <div className="hero-content">
                        <p className="banner-tags">Design & Development</p>
                        <svg className="ani-svg" version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 43.1 85.9" >
                            <path strokeLinecap="round" strokeLinejoin="round" className="st0 draw-arrow" d="M11.3,2.5c-5.8,5-8.7,12.7-9,20.3s2,15.1,5.3,22c6.7,14,18,25.8,31.7,33.1" />
                            <path strokeLinecap="round" strokeLinejoin="round" className="draw-arrow tail-1" d="M40.6,78.1C39,71.3,37.2,64.6,35.2,58" />
                            <path strokeLinecap="round" strokeLinejoin="round" className="draw-arrow tail-2" d="M39.8,78.5c-7.2,1.7-14.3,3.3-21.5,4.9" />
                        </svg>
                        <h1 className="hedding-banner"><span>Build</span> <span>Professional</span></h1>
                        <h1 className="hedding-banner-two">Website to Grow Your Business</h1>
                        <a className="hero-btn vs-btn" href="/about">See More</a>
                        <svg className="ani-svg-two" width="125" height="110" viewBox="0 0 125 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="draw-arrow tail-1" d="M95.1152 1.62105C95.1152 1.62105 111.026 27.5255 100.288 44.4158C92.7122 56.3326 74.5195 59.6328 69.0225 50.8629C66.115 46.2241 70.7395 42.9765 75.0952 43.2757C82.9676 43.8164 88.8374 58.9922 86.6317 67.8597C78.9818 98.6139 26.9106 102.378 26.9106 102.378" stroke="#FC4D36" strokeWidth="2" strokeLinecap="round"/>
                            <path className="draw-arrow tail-2" d="M34.1343 106.957L26.3768 102.696L30.2729 95.5254" stroke="#FC4D36" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </div>
                </div>
            </section>
        </>
    )
}

export default  HomeBanner; 