import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';

import Logowhite from "../assets/images/logo-white.png";
import PhoneIcon from "../assets/images/f-phone.png";
import LocationIcon from "../assets/images/f-pin.png";
import EnvelopeIcon from "../assets/images/f-envelope.png";
import FacebookIcon from '../assets/svg/Facebook-icon';
import LinkdinIcon from "../assets/svg/Linkdin-icon";
import InstagramIcon from '../assets/svg/Instagram-icon';

import CodeIcon from "../assets/images/code-wanimate.png";
import CSSIcon from "../assets/images/css-wanimate.png";
import LaravelIcon from "../assets/images/laravel-wanimate.png";
import MysqlIcon from "../assets/images/mysql-wanimate.png";
import PHPIcon from "../assets/images/php-wanimate.png";
import WooIcon from "../assets/images/woo-wanimate.png";
import WordpressIcon from "../assets/images/wordpress-wanimate.png";

const Footer = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  return (
    <>
      <footer id="footer" className="site-footer">
        
        <div className="iconanimate-div">
                <div className="iconanimate-leftpart">
                    <span className="codeicon-animate"><img src={CodeIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="cssicon-animate"><img src={CSSIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="laravelicon-animate"><img src={LaravelIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="mysqlicon-animate up-down"><img src={MysqlIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="phpicon-animate"><img src={PHPIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="wooicon-animate"><img src={WooIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="wordpressicon-animate"><img src={WordpressIcon} alt="VIVID WEB SOLUTIONS" /></span>
                </div>
                <div className="iconanimate-rightpart">
                    <span className="codeicon-animate"><img src={CodeIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="cssicon-animate"><img src={CSSIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="laravelicon-animate"><img src={LaravelIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="mysqlicon-animate up-down"><img src={MysqlIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="phpicon-animate"><img src={PHPIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="wooicon-animate"><img src={WooIcon} alt="VIVID WEB SOLUTIONS" /></span>
                    <span className="wordpressicon-animate"><img src={WordpressIcon} alt="VIVID WEB SOLUTIONS" /></span>
                </div>
            </div>
          
          <Container>  
            <div className="footer-logo text-center">        
                <Link to="/" className="foot-logo-a">
                  <img src={Logowhite} alt="VIVID WEB SOLUTIONS" />
                </Link>           
            </div>
            <ListGroup className="comp-info">
                <ListGroup.Item className="comp-info-li">
                  <div className="comp-info-box">
                        <span><img src={PhoneIcon} alt="VIVID WEB SOLUTIONS" /></span> 
                        <div className="comp-info-text">
                            <strong>Call us</strong>
                            <Link to="#" onClick={() => window.location = 'tel:+918469715630'}>+91 8469715630</Link> 
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="comp-info-li">
                  <div className="comp-info-box">
                      <span><img src={LocationIcon} alt="VIVID WEB SOLUTIONS" /></span> 
                        <div className="comp-info-text">
                            <strong>Office location</strong>
                            <Link to="#" onClick={() => window.location = 'https://maps.google.com/?q=vivid'}>359, Shiven square business hub, pal, Surat</Link>                            
                        </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="comp-info-li">
                  <div className="comp-info-box">
                        <span><img src={EnvelopeIcon} alt="VIVID WEB SOLUTIONS" /></span> 
                        <div className="comp-info-text">
                            <strong>Write to us</strong>
                            <Link to="#" onClick={() => window.location = 'mailto:yourmail@domain.com'}>info@vividwebsolutions.in</Link>
                        </div>
                  </div>
                </ListGroup.Item>
            </ListGroup>                             
          </Container>

        <div className="copyright-text">
            <p>&copy; 2024 All rights reserved Vivid Web Solutions</p>
            <ListGroup className="footer-social-ul">
              <ListGroup.Item action target="_blank" href="https://www.facebook.com/people/Vivid-Web-Solutions/100064129593173/">
                <FacebookIcon />
              </ListGroup.Item>
              <ListGroup.Item action target="_blank" href="https://www.linkedin.com/company/vivid-web-solutions-surat/">
                <LinkdinIcon />
              </ListGroup.Item>
              <ListGroup.Item action target="_blank" href="https://instagram.com/vividwebsolutions?igshid=YmMyMTA2M2Y=">
                <InstagramIcon />
              </ListGroup.Item>
            </ListGroup>                            
        </div>
    </footer> 
    {showButton && (
      <span onClick={scrollToTop} className="back-to-top"></span>
    )}
    </>

    
  );
};

export default Footer;
