import React from "react";
import kara from "../assets/images/kara.webp"
import shabanali from "../assets/images/shabanali-logo.webp"
import annray from "../assets/images/ann-ray.webp"
import gil from "../assets/images/gil.webp"
import ins from "../assets/images/ins-logo.webp"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const HomeTestimonial = () => {
    return(
        <>
        <section className="our-testimonails-sec position-relative">
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            <span className="circle-vector"></span>
            <div className="container">
                <div className="site-title">
                    <p>Testimonial</p>
                    <h3>The Testimonial That's Amazing!</h3>
                </div>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={50}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        280: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                        },
                        1024: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                      }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                            <div className="testimonails-box position-relative">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="client-img">
                                        <img src={kara} alt="client" />
                                    </div>
                                    <h5>Kara N.<span>(USA)</span></h5>
                                </div>
                                <p>
                                    “The team at Vivid is incredibly skilled when it comes to custom WordPress
                                    development. I feel confident recommending them for any programming project as they
                                    are thoughtful in crafting their solutions and detailed in their work. Communication
                                    has been easy and they are readily available to answer any questions. Vivid is
                                    fantastic to work with!”
                                </p>
                            
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="testimonails-box position-relative">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="client-img">
                                        <img src={gil} alt="client" />
                                    </div>
                                    <h5>Gil Farkash<span></span></h5>
                                </div>
                               
                                <p>
                                    “I have had a positive experience with Vivid Web Solutions over a number of years.<br/>
                                    Everything you need as a beginner or a novice (which is me) to build your own website. On a general level I'd definitely recommend this company to anyone who has absolutely ZERO website development and design knowledge. The team provided all the templates and layouts that I needed, designed and developed a website to meet my requirements. The end result is extremely professional and the whole site is very versatile.<br/>I was delighted to find out that I could e-mail or Skype whatever problem I was having to the team and would receive a prompt reply either explaining exactly what I needed to do and how or resolving the problem. The team always understood exactly what I'm trying to do and come up with ways of achieving this.<br/>I have absolutely no hesitation in recommending Vivid Solutions, and they are now developing another site for me.”
                                </p>
                             
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="testimonails-box position-relative">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="client-img">
                                        <img src={annray} alt="client" />
                                    </div>
                                    <h5>Ann Ray<span></span></h5>
                                </div>
                                <p>
                                    “Megha has been a great support for my very first web site that she delivered very quickly. She was responsive, deadline-driven, attentive to details, with very good communication skills, being friendly and professional. I highly enjoyed working with her. Thank you Megha for your great support!”
                                </p>
                              
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="testimonails-box position-relative">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="client-img">
                                        <img src={shabanali} alt="client" />
                                    </div>
                                    <h5>Shaban Ali<span></span></h5>
                                </div>
                                <p>
                                    “Punita is a talented developer and we been working together on a number of projects now.   Her communication is excellent and she keeps me informed throughout. A solution based thinker has a solution for every challenge.”
                                </p>
                               
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="testimonails-box position-relative">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="client-img">
                                        <img src={ins} alt="client" />
                                    </div>
                                    <h5>Ins<span></span></h5>
                                </div>
                                <p>
                                    “Excellent group to work with!! Young, smart and High energy team doing great work. Excellent attitude and intentions, keep advancing with confidence!”
                                </p>
                               
                            </div>
                    </SwiperSlide>
                    </Swiper>
            </div>
        </section>
        </>
    )
}

export default HomeTestimonial;